<template>
  <div>
    TURN is {{ turn ? "working" : "not working" }}
  </div>
</template>

<script>
export default {
  name: 'TurnTest',
  data() {
    return {
      turn: false
    }
  },
  methods: {
    checkTURN(turnConfig, timeout) {
      return new Promise(function(resolve) {

        setTimeout(function(){
          if(promiseResolved) return;
          resolve(false);
          promiseResolved = true;
        }, timeout || 5000);

        var promiseResolved = false
            , myPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection   //compatibility for firefox and chrome
            , pc = new myPeerConnection({iceServers:[turnConfig]})
            , noop = function(){};
        pc.createDataChannel("");    //create a bogus data channel
        pc.createOffer(function(sdp){
          if(sdp.sdp.indexOf('typ relay') > -1){ // sometimes sdp contains the ice candidates...
            promiseResolved = true;
            resolve(true);
          }
          pc.setLocalDescription(sdp, noop, noop);
        }, noop);    // create offer and set local description
        pc.onicecandidate = function(ice){  //listen for candidate events
          if(promiseResolved || !ice || !ice.candidate || !ice.candidate.candidate || !(ice.candidate.candidate.indexOf('typ relay')>-1))  return;
          promiseResolved = true;
          resolve(true)
        }
      })
    }
  },
  mounted() {
    this.checkTURN({
      // urls: 'turn:31.210.174.155:3478',
      // urls: 'turn:sfu-vpc.study.skilllms.com:3478',
      // username: 'turnstun',
      // credential: 'turnstun'

      // url: 'turn:a.relay.metered.ca:80',
      // credential: '05045f1b9fc21d73f35905fd',
      // username: 'EU9DAnwJM+uM42/q'

      url: 'turn:turn.tu.kielce.pl:443?transport=tcp',
      username: 'meet2',
      credential: 'MjQUYR6712bCsajy842KJAhPow'

      // urls: 'turn:turn.interes.io:5349',
      // username: 'turnstun',
      // credential: 'turnstun'
    }).then(function(bool) {
      // this.turn = bool
      console.log('is TURN server active? ', bool? 'yes':'no');
    }).catch((err) => {
      this.turn = false
      console.log(err)
      // console.error.bind(console)
    })
  }
}
</script>

